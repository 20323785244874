/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, unsafeCSS } from 'lit-element';
import { displayFlex, endJustified, horizontal, startJustified } from 'lit-flexbox-literals';
import { _THEME } from '../../../../themes/styles';

export const LdSettingsViewStyles = css`
        
    ld-buttonlist {
        width: 25%;
    }

    ld-button {
        display: block;
        margin-top: 5px;
    }

    ::part(ld-part-button) {
        display: block;
        margin: 12px 0px;
        background-color: ${unsafeCSS(_THEME.colors.button_background_color)};
        color: ${unsafeCSS(_THEME.colors.primary_color)};
        font-weight: bold;
        text-align: center;
    }
        
    .ld-limit-list-wrapper .headline {
        background-color: #fff;
        height: auto;
        padding: 2px 5px;
        ${displayFlex}
        ${horizontal}
        ${startJustified}
        margin-bottom: 5px;
        margin-right: 0px;
    }

    .ld-limit-list-wrapper .headline > * {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .ld-limit-list-wrapper .headline h3 {
        text-transform: uppercase;
        font-size: 18px;
        margin: 10px 15px;
        color: ${unsafeCSS(_THEME.colors.primary_header_color)};
    }

    .ld-limit-list-wrapper .headline h3::before {
        content: "/ ";
        font-weight: 800;
        color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    }

    .ld-limit-list-wrapper .headline ld-datepicker-config {
        display: block;
        margin-left: auto;
        padding-top: 8px;
        width: 600px !important;
        max-width: 600px !important;
        ${endJustified}
    }

    .ld-limit-list-wrapper .headline .heading-icons {
        ${endJustified}
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-top: 6px;
        border-left: 1px ${unsafeCSS(_THEME.colors.icons_color)} solid;
    }

    .ld-limit-wrapper {
        color: ${unsafeCSS(_THEME.colors.secondary_text_color)};
        background-color: ${unsafeCSS(_THEME.colors.primary_background_color)};
        margin-bottom: 5px;
        padding: 25px;
        margin-right: 0px;
    }

    @media only screen and (min-width: 600px ) {
        .disabledOnPad {
            display: none;
        }
    }

    @media only screen and (min-width: 1260px ) {
        .disabledOnPad {
            display: inline;
        }
    }
`;

export default LdSettingsViewStyles;
