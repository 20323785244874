/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { LitElement, html, TemplateResult } from 'lit';
import {ifDefined} from 'lit/directives/if-defined.js';

import '@polymer/paper-input/paper-input';
import '@polymer/paper-checkbox/paper-checkbox';
import '@lifedata/ld-elements/dist/atoms/ld-dropdown';
import '@polymer/paper-input/paper-textarea';
import '@polymer/paper-item/paper-item';
import '@polymer/paper-listbox/paper-listbox';
import '@polymer/iron-icon/iron-icon';
import '@polymer/iron-icons/iron-icons';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-toggle-button/paper-toggle-button'
import '../ld-datepicker-config';
import { LdI18NextMixin } from '../../ld-application/i18n/ld-i18next';

import { LdFormStyles } from './styles';
import { sortByName, valuesHaveNameProperty } from '../../../js/LdGlobal';

import moment from "moment";
import { FormElement, DropdownValue } from '../../../typesAndInterfaces/Forms';

/**
 * @extends LitElement
 */
class LdFormWrapper extends LdI18NextMixin(LitElement) {

  // --------------------------------------------------------------------------------------
  static get properties() {
    return {
      formInputs: { type: Object, hasChanged: () => true },
      loading: { type: Boolean, value: false, hasChanged: () => true },
      _settings: { type: Object, hasChanged: () => true },
      _value: { type: String, hasChanged: () => true }
    };
  }

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdFormStyles];
  }

  handleInputChange(e: InputEvent, item: FormElement) {
    const eventTarget = e.target as HTMLInputElement;
    this.dispatchEvent(new CustomEvent("form-data-changed", {
      composed: true,
      bubbles: true,
      detail: {
        data: eventTarget.value,
        id: item.id
      }}
      )
    )
  }

  onChangeHandler(item: FormElement) {
    return (e: InputEvent) => this.handleInputChange(e, item)
  }

  // --------------------------------------------------------------------------------------
  render() {
    const formInputs: Array<TemplateResult> = [];
    if (typeof this.formInputs !== 'undefined') {
      this.formInputs.forEach((item: FormElement) => {
        if (!item.hidden) {
          /**
           * create input fields
           */
          switch(item.type){
            case 'input':
              formInputs.push(html`
              <paper-input 
                id='${item.id}'
                tabindex='${item.tabIndex}'
                @change="${this.onChangeHandler(item)}"
                label='${item.label}'
                ?disabled='${item.disabled}'
                ?auto-validate='${item.pattern != null}'
                pattern='${item.pattern ?? ''}'
                value='${item.value ?? ''}'
                ?readonly='${item.readonly}'
                error-message='${item.errorMessage ?? ''}'>
                ${item.readonly === true ? html`<iron-icon icon='block' slot="suffix"></iron-icon>` : ''}
              </paper-input>
            `);
              break;
            case 'hidden':
              formInputs.push(html`
              <paper-input 
                id='${item.id}'
                value='${item.value ?? ''}'
                style='display:none;'
              ></paper-input>
            `);
            break;
            case 'textarea':
              formInputs.push(html`
              <paper-textarea
                id='${item.id}'
                tabindex='${item.tabIndex}'
                label='${item.label}'
                value='${item.value ?? ''}'
                ?disabled='${item.disabled}'
                error-message='${item.errorMessage ?? ''}'>
                ${item.readonly === true ? html`<iron-icon icon='block' slot="suffix"></iron-icon>` : ''}
              ></paper-textarea>
            `);
              break;
            case 'toggle':
              formInputs.push(html`
              <paper-toggle-button
                id='${item.id}'
                class='red'
                tabindex='${item.tabIndex}'
                ?checked='${item.checked ?? '' }'
                value='${item.value ?? ''}'
                ?disabled='${item.disabled}'
                error-message='${item.errorMessage ?? ''}'>${item.label}
              </paper-toggle-button>
            `);
              break;
            case 'dropdown':
              const values = item.value as unknown as Array<DropdownValue>;
              formInputs.push(html`
              <paper-dropdown-menu 
                tabindex='${item.tabIndex}'
                id='${item.id}'
                label='${item.label}'
                error-message='${item.errorMessage ?? ''}'>
                <paper-listbox
                  label='${item.labelListBox}' 
                  slot='dropdown-content'
                  selected='${item.selected ?? ''}' 
                  class='${item.class}' 
                  attr-for-selected='cid'
                  >
                    ${this._loadPaperItemValue(values)}
                  </paper-listbox>
              </paper-dropdown-menu>
            `);
              break;
            case 'file':
              formInputs.push(html`
              <paper-input
                type='file'
                accept='${item.accept || '*'}'
                id='${item.id}'
                label='${item.label}'
                value='${item.value ?? ''}'
                ?disabled='${item.disabled}'
                error-message='${item.errorMessage ?? ''}'
              ></paper-input>
            `);
              break;
            case 'datepicker':
              formInputs.push(html`
              <ld-datepicker-config 
                id='${item.id}'
                ?noTimeSelect='${item.noTimeSelect}'
                ?singleDaySelect='${item.singleDaySelect}' 
                ?noPresets='${item.noPresets}'  
                ?blockFutureDates='${item.blockFutureDates}' 
                ?usePredefinedDate='${item.usePredefinedDate}' 
                addTitle='${item.label}'
                date='${ifDefined(item.value)}'
                value='${item.value != null ? moment(item.value).format('YYYY-MM-DD') : ''}'
                @changed='${(evt: InputEvent) => this._dateChanged(evt)}'
              ></ld-datepicker-config>
            `);
              break;
            case 'lit-html':
              const result = item.value as unknown as Function;
              formInputs.push(result());
              break;
          }
        }
      });
    }

    return html`
      ${formInputs}
    `;
  }


  /**
   * Create the Dropdowns for a dropdown field the array must have id and name
   * @param  {} values
   */
  // --------------------------------------------------------------------------------------
  _loadPaperItemValue(value: Array<DropdownValue>) {
    const paperItem: TemplateResult[] = [];

    if (valuesHaveNameProperty(value)) value.sort(sortByName);

    if (typeof value !== 'undefined') {
      value.forEach((item) => {
        paperItem.push(html`
        <paper-item
          cid='${item.id}'>
          ${this._truncateFullName(item)}
        </paper-item>
      `);
      });
    }
    return paperItem;
  }

  /**
   * @param  {} name
   */
  // --------------------------------------------------------------------------------------
  /* eslint-disable */
  _truncateFullName(item: DropdownValue) {
    let name;
    if (typeof item.name !== 'undefined') {
      name = item.name;
    } else if (item.title) {
      name = item.title;
    } else {
      throw new Error("item with unknown structure provided: " + JSON.stringify(item))
    }
    return (name.length > 80) ? `${name.substr(0, 80)}\u2026` : name;
  }

  // --------------------------------------------------------------------------------------
  _dateChanged(evt: InputEvent) {
    const eventTarget = evt.target as unknown as HTMLElement;
    const detail = evt.detail as unknown as {start: string};
    this.shadowRoot.querySelector(`ld-datepicker-config#${eventTarget.getAttribute('id')}`).setAttribute('value', moment(detail.start).format('YYYY-MM-DD'))
  }

}

customElements.define('ld-form-wrapper', LdFormWrapper as unknown as CustomElementConstructor);
