/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */

import {
  BREADCRUMB_CONFIG,
  CLEAR_SUBMIT_BREADCRUMB,
  ERROR_LOADING_BREADCRUMB_CONFIG,
  FINISH_LOADING_BREADCRUMB_CONFIG,
  START_LOADING_BREADCRUMB_CONFIG,
} from '../actions/breadcrumb-actions';

// @ts-ignore
const BreadCrumbReducer = (state = {}, action: any) => {

  switch (action.type) {
    case BREADCRUMB_CONFIG:
      return {
        ...state,
        breadCrumbConfig: action.breadCrumbConfig,
      };
    case CLEAR_SUBMIT_BREADCRUMB:
      return {
        ...state,
        breadCrumb: null,
        breadCrumbConfig: null,
      };
    case START_LOADING_BREADCRUMB_CONFIG:
      return {
        ...state,
        loadingBreadCrumb: true,
      };
    case FINISH_LOADING_BREADCRUMB_CONFIG:
      return {
        ...state,
        loadingBreadCrumb: false,
        breadCrumb: action.breadcrumbList,
      };
    case ERROR_LOADING_BREADCRUMB_CONFIG:
      return {
        ...state,
        result: action.error,
        loadingBreadCrumb: false,
      };
    default:
      return state;
  }
};

export default BreadCrumbReducer;
