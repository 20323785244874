/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { html, LitElement } from 'lit-element';
import { connect } from 'pwa-helpers/connect-mixin';
import '@polymer/app-layout/app-drawer/app-drawer';
import '@polymer/app-layout/app-header/app-header';
import '@polymer/app-layout/app-toolbar/app-toolbar';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-menu-button/paper-menu-button';
import '@polymer/paper-icon-button/paper-icon-button';
import '@polymer/iron-icons/iron-icons';
import '@polymer/paper-dialog/paper-dialog';
import '@polymer/paper-toast/paper-toast';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable';
import { store } from '../ld-redux/ld-redux-store';
import { LdI18NextMixin } from '../ld-application/i18n/ld-i18next';
import { LdPlatformStyles } from './ld-platform-styles';
import { CONFIG, forward, isUserUserOEM } from '../../js/LdGlobal';
import {
	acceptPrivacyPolicy,
	checkPrivacyPolicyStatus,
	loadUserSetting,
	logout,
	saveUserSetting,
} from '../ld-redux/actions/ld-users-actions';
import { closeDisplayNotification, displayNotification } from '../../js/LdNotificationHandler';
import './ld-sidebar/ld-sidebar';
import './ld-title/ld-title';
import './ld-search/ld-search-input';
import '../ld-application/ld-breadcrumb';
import { checkRelease } from '../ld-redux/actions/ld-application-actions';
import moment from 'moment';

import '../ld-login-application'
import '../ld-platform/modules/settings/module'

/**
 * Class for displaying a &lt;ld-platform&gt; element.
 * @extends LitElement
 * @mixes connect
 * @mixes LdI18NextMixin
 */
class LdPlatform extends connect(store)(LdI18NextMixin(LitElement)) {

	// --------------------------------------------------------------------------------------
	constructor() {
		super();
		this._pageModules = JSON.stringify([
			{
				id: 'dashboard',
				name: 'modules.frameTitle.dashboard',
				icon: 'dashboard',
				href: '/dashboard',
			},
			{
				id: 'assets',
				name: 'modules.frameTitle.assets',
				icon: 'assets',
				href: '/assets',
			},
			{
				id: 'users',
				name: 'modules.frameTitle.users',
				icon: 'users',
				href: '/users/list',
				swOnly: true,
				restrictedTo: ['OEM_USER_MANAGER'],
			},
			{
				id: 'settings',
				name: 'modules.frameTitle.settings',
				icon: 'settings',
				href: '/settings/limit/list',
				swOnly: true,
				restrictedTo: ['DEVELOPER','SUPERUSER'],
			},
			{
				id: 'netcfg',
				name: 'netcfg',
				href: '/netcfg',
				show: false,
			},
			{
				id: '404',
				name: '404',
				show: false,
			},
			{
				id: 'qs',
				name: 'modules.frameTitle.qs',
				icon: 'qs',
				href: '/qs/list',
				swOnly: true,
				restrictedTo: ['OEM_QA'],
			},
			{
				id: 'forgot-password',
				name: 'forgot-password',
				href: '/forgot-password',
				show: false,
			},
			{
				id: 'new-password',
				name: 'new-password',
				href: '/new-password',
				show: false,
			},
			{
				id: 'change-password',
				name: 'change-password',
				href: '/change-password',
				show: false,
			},
			{
				id: 'login',
				name: 'login',
				href: '/login',
				show: false,
			},
		]);
		this._showLoginScreen = false;
	}

	// --------------------------------------------------------------------------------------
	static get properties() {
		return {
			_pageModule: { type: String, reflect: true },
			_pageModules: { type: Object, reflect: true },
			_pageModuleContent: { type: Object, reflect: true },
			_userInformation: { type: Object, reflect: true },
			_userSettings: { type: Object, reflect: true },
			_subModules: { type: Object, hasChanged: () => true },
			_releaseInfo: { type: Object, hasChanged: () => true },
			_showLoginScreen: { type: Boolean },

		};
	}

	// --------------------------------------------------------------------------------------
	static get styles() {
		return [LdPlatformStyles];
	}

	// --------------------------------------------------------------------------------------
	connectedCallback() {
		super.connectedCallback();

		this.dontShowReleaseNote = this.dontShowReleaseNote.bind(this);
		window.addEventListener('closedRelease', this.dontShowReleaseNote);

	}

	// --------------------------------------------------------------------------------------
	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('closedRelease', this.dontShowReleaseNote);
	}

	firstUpdated(_changedProperties) {
		if(!this._showLoginScreen) store.dispatch(checkRelease());
	}

	// --------------------------------------------------------------------------------------
	updated(changedProperties) {
		if (changedProperties.has('_userInformation')) {
			this._pageModules = JSON.parse(this._pageModules);
			if (isUserUserOEM(this._userInformation)) {
				this._pageModules[1].href = '/assets/companies/list';
			} else {
				this._pageModules[1].href = `/assets/locations/list/${this._userInformation.company}`;
			}
			this._pageModules = JSON.stringify(this._pageModules);
		}

	}

	/**
	 * render method, used to display the rendered template
	 *
	 * @returns {String} Rendered literals template
	 */
	// --------------------------------------------------------------------------------------
	render() {
		let loadedPageModule;
		if (this._pageModule != null ) {
			switch (this._pageModule) {
				case 'dashboard':
					loadedPageModule = html`
            <ld-dashboard .userInformation='${this._userInformation}' ._subModules='${this._subModules}'
                          class='_pageModule' active></ld-dashboard>`;
					break;
				case 'assets':
					loadedPageModule = html`
            <ld-assets class='_pageModule' ._userInformation='${this._userInformation}'
                       ._subModules='${this._subModules}' active></ld-assets>`;
					break;
				case 'users':
					loadedPageModule = html`
            <ld-users class='_pageModule' active></ld-users>`;
					break;
				case 'qs':
					loadedPageModule = html`
            <ld-qs class='_pageModule' active></ld-qs>`;
					break;
				case 'settings':
					loadedPageModule = html`
						<ld-settings class='_pageModule' active></ld-settings>`;
					break;
				case 'netcfg':
					loadedPageModule = html`
            <ld-netcfg class='_pageModule' active picture='../../../images/service/hotlinePerson.svg'></ld-netcfg>`;
					break;
				case 'login':
					this._showLoginScreen=true;
					loadedPageModule = html`
						<login-view class='_pageModule' active></login-view>`;
					break;
				case 'forgot-password':
					this._showLoginScreen=true;
					loadedPageModule = html`
						<forgot-password-view class='_pageModule' active></forgot-password-view>`;
					break;
				case 'new-password':
					this._showLoginScreen=true;
					loadedPageModule = html`
						<create-new-password-view class='_pageModule' active></create-new-password-view>`;
					break;
				case 'change-password':
					this._showLoginScreen=true;
					loadedPageModule = html`
						<change-password-view class='_pageModule' active></change-password-view>`;
					break;
				default:
					loadedPageModule = html`
            <ld-404 class='_pageModule' active></ld-404>`;
					break;
			}
		}

		let main = html``;
		if(!this._showLoginScreen && this._userInformation.role != null){
			main = html`
			 <!-- QUIRKS -->
      <!-- this is necessary as otherwise we can't access the shim -->
      <style>
        app-drawer {
          --app-drawer-scrim-background: rgba(36, 58, 79, 1);
          --app-drawer-content-container: {
            background-color: rgba(36, 58, 79, 1) !important;
            top: 64px;
          };
        }
      </style>

      <ld-privacy-policy @policy-status-changed='${e => this._onPolicyStatusChanged(e)}'></ld-privacy-policy>
      <paper-toast id='ld-toast' duration='0' class='fit-bottom'>
        <span class='ld-toast-message'>This is a dummy text which should be replaced by a real message.</span>
        <paper-button @click='${() => closeDisplayNotification()}'>${this.LdTranslate('generic.closeLabel')}
        </paper-button>
      </paper-toast>
      <!-- header -->
      <app-header fixed condenses class='sticky'>
        <app-toolbar sticky>

          <!-- sw icon -->
          <a href='/' id='sw-home-link'>
            <iron-icon id='sw-logo' src='/images/swlogo.svg' slot='item-icon'></iron-icon>
          </a>
          <paper-tooltip for='sw-logo' position='bottom'>${this.LdTranslate('generic.backToDashboard')}</paper-tooltip>


          <ld-search-input></ld-search-input>

          <!-- page title -->
          <div main-title>
            <ld-title
              .pageModules='${this._pageModules}'
              .baseWindowTitle='${CONFIG.generic.base_window_title}'
              updateWindowTitle>
            </ld-title>
          </div>

          <!-- profile / notification icon -->
          <div id='profile' @click='${e => this.userProfile(e)}'>
            <div class='sw-header-bar-icon-profile'>
              <iron-icon id='sw-account' src='/images/icon_person.svg' slot='item-icon'></iron-icon>
            </div>
            <div class='sw-header-bar-account-name'>
              <span class='account-name'>
                ${this._userInformation.given_name} ${this._userInformation.family_name}
              </span>
            </div>
          </div>
          <paper-tooltip for='profile' position='bottom'>${this.LdTranslate('generic.userProfile')}</paper-tooltip>

          <div class='sw-header-bar-icon-profile'>
            <iron-icon id='sw-logout' src='/images/icon_logout.svg' slot='item-icon'
                       @click='${() => this.logout()}'></iron-icon>
          </div>
          <paper-tooltip for='sw-logout' position='bottom'>${this.LdTranslate('generic.logout')}</paper-tooltip>

          <!-- profile dropdown -->
          <paper-menu-button horizontal-align='right' vertical-align='top'>
            <paper-icon-button icon='icons:arrow-drop-down' class='dropdown-trigger'></paper-icon-button>
            <div id='user-dropdown' class='dropdown-content'>
              <p>&nbsp;</p>
            </div>
          </paper-menu-button>
        </app-toolbar>
      </app-header>

      <!-- drawer content -->
      <app-drawer opened='true' disable-swipe='true' persistent='true'>
        <nav class='drawer-list'>
          <ld-sidebar .activeModule='${this._pageModule}' ._userInformation='${this._userInformation}'
                      .pageModules='${this._pageModules}'></ld-sidebar>
        </nav>
      </app-drawer>

      <!-- main content -->
      <main role='main' class='main-content vertical layout'>
        <div class='main-content-inner-wrapper'>
          <ld-breadcrumb></ld-breadcrumb>

          ${loadedPageModule}
        </div>
      </main>
			       <!-- footer content -->
      <footer>
        <ul class='flex'>
          <li><a href='${this.LdTranslate('generic.footerLinks.swHomepage.url')}'
                 target='_blank'>${this.LdTranslate('generic.footerLinks.swHomepage.title')}</a></li>
          <li><a href='${this.LdTranslate('generic.footerLinks.imprint.url')}'
                 target='_blank'>${this.LdTranslate('generic.footerLinks.imprint.title')}</a></li>
          <li><a href='${this.LdTranslate('generic.footerLinks.gtct.url')}'
                 target='_blank'>${this.LdTranslate('generic.footerLinks.gtct.title')}</a></li>
          <li><a href='javascript:;'
                 @click='${e => this._onPrivacyFooterLinkClick(e)}'>${this.LdTranslate('generic.footerLinks.privacy.title')}</a>
          </li>
          <li><a
            href='${this.LdTranslate('generic.footerLinks.contact.url')}'>${this.LdTranslate('generic.footerLinks.contact.title')}</a>
          </li>
        </ul>
      </footer>
`
		}

		return html`
     
			${main}

		`;
	}

	/**
	 * @param {JSON} state - updated state handled by the redux implementation
	 */
	// --------------------------------------------------------------------------------------
	stateChanged(state) {
		const setting = state.LdUsersReducer;

		if (state.LdUsersReducer.info != null && this._userInformation !== state.LdUsersReducer.info) {
			this._userInformation = state.LdUsersReducer.info;
		}

		if (state.LdUsersReducer.settings != null && this._userSettings !== state.LdUsersReducer.settings) {
			this._userSettings = state.LdUsersReducer.settings;
			this.checkIfToastReleaseNeeded();
		}
		if (state.LdApplicationReducer.subModules != null && this._subModules !== state.LdApplicationReducer.subModules) {
			this._subModules = state.LdApplicationReducer.subModules;
		}
		if (state.LdApplicationReducer.pageModule != null && this._pageModule !== state.LdApplicationReducer.pageModule) {
			this._pageModule = state.LdApplicationReducer.pageModule;
		}
		if (state.LdApplicationReducer.releaseInfo != null && state.LdApplicationReducer.releaseInfo.release_number != null && this._releaseInfo !== state.LdApplicationReducer.releaseInfo) {
			this._releaseInfo = state.LdApplicationReducer.releaseInfo;
			store.dispatch(loadUserSetting(`releaseNote`));
		}

	}

	// --------------------------------------------------------------------------------------
	logout() {
		store.dispatch(logout());
	}

	/**
	 * Handle what happens when the User button is clicked
	 */
	// --------------------------------------------------------------------------------------
	userProfile(evt) {
		forward('/users/info', { evt });
	}

	/**
	 * Handle what happens when the Privacy button is clicked
	 */
	// --------------------------------------------------------------------------------------
	_onPrivacyFooterLinkClick() {
		this.shadowRoot.querySelector('ld-privacy-policy').setLanguage(this.LdGetLanguage());
		this.shadowRoot.querySelector('ld-privacy-policy').disableAcceptDeclineButton();
		this.shadowRoot.querySelector('ld-privacy-policy').open();
	}

	// --------------------------------------------------------------------------------------
	_onPolicyStatusChanged(e) {
		if (e.detail.accepted === false) {
			store.dispatch(acceptPrivacyPolicy(false));
		} else {
			store.dispatch(acceptPrivacyPolicy(true));
			this.shadowRoot.querySelector('ld-privacy-policy').close();
			setTimeout(() => {
				store.dispatch(checkPrivacyPolicyStatus());
			}, 50);
		}
	}

	// --------------------------------------------------------------------------------------
	dontShowReleaseNote() {
		const releaseConfig = {
			readInfo: true,
			release_number: this._releaseInfo.release_number,
		};
		store.dispatch(saveUserSetting(`releaseNote`, releaseConfig));
	}

	// --------------------------------------------------------------------------------------
	checkIfToastReleaseNeeded() {
		if (this._releaseInfo == null || this._userSettings[`releaseNote`] == null) return;
		let read = true;
		if (Object.keys(this._userSettings[`releaseNote`]).length === 0 || !this._userSettings[`releaseNote`].readInfo || this._userSettings[`releaseNote`].release_number !== this._releaseInfo.release_number) {
			read = false;
		}
		if (read) return;
		let message = this.LdTranslate('generic.release').replace('%replaceValue1', moment(this._releaseInfo.start).format(this.LdTranslate('modules.generic.dateFormat'))).replace('%replaceValue2', moment(this._releaseInfo.start).format(this.LdTranslate('modules.generic.timeFormatWithoutSeconds'))).replace('%replaceValue3', moment(this._releaseInfo.end).format(this.LdTranslate('modules.generic.timeFormatWithoutSeconds')));
		let notificationType = 'releaseNotes';
		displayNotification(notificationType, message, 5, false);

	}
}

customElements.define('ld-platform', LdPlatform);
