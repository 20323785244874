/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { css, html, LitElement, property, state } from 'lit-element';
import { TemplateResult }                         from 'lit-html';
import { connect }                                         from 'pwa-helpers/connect-mixin';
import { store }                                           from '../../../ld-redux/ld-redux-store';
import { LdSettingsViewStyles }                            from './styles';
import {
    forward, isUserSwEmployee,
    isUserUserOEM_QA,
} from '../../../../js/LdGlobal';
import '@polymer/paper-fab/paper-fab';
import '@polymer/iron-icons/iron-icons';
import { LdBreadCrumb }                                    from '../../../ld-application/ld-breadcrumb';

import './limits';
import {
    BasicRouter,
    Router,
}                                                          from '../../../../../src/js/services/routingConfig';

/**
 * @extends LitElement
 */
export class LdSettingsView extends connect(store)(LitElement) {
    // --------------------------------------------------------------------------------------

    @property({ type: Object, hasChanged: () => true }) _subModules: Array<Object> = [];
    @property({ type: Object }) _userInformation: Object                           = {};
    @state() private router: Router                                                = new BasicRouter();


    // --------------------------------------------------------------------------------------
    static get styles() {
        return [LdSettingsViewStyles, css`
          :host {
            display: flex;
            flex-direction: column;
            flex: auto;
          }

          .container {
            flex: auto;
            display: flex;
            flex-direction: column;
            margin-right: 50px;
          }

          .grid-container {
            display: flex;
            flex-direction: column;
            flex: auto;
          }

          ld-assets-header {
            margin: 20px 0;
          }`];
    }

    /**
     * to prevent memory leaks
     * @returns void
     */
    // --------------------------------------------------------------------------------------
    disconnectedCallback() {
        super.disconnectedCallback();
        LdBreadCrumb.setBreadcrumb({
            assetType: null,
            assetID: null,
            backButtonLink: null,
        });
    }


    // --------------------------------------------------------------------------------------
    render(): TemplateResult {

        if (typeof this._userInformation !== 'undefined') {
            if (!isUserSwEmployee(this._userInformation)) {
                this.router.changeView(`/dashboard`, { useSpinner: true })
            }
        }

        return html`
            <ld-limit-list ._subModules='${this._subModules}'
                           ._userInformation='${this._userInformation}'></ld-limit-list>`;
    }

    // --------------------------------------------------------------------------------------

    stateChanged(state: any) {
        this._subModules      = state.LdApplicationReducer.subModules;
        this._userInformation = state.LdUsersReducer.info;
    }

}


customElements.define('ld-settings', LdSettingsView as unknown as CustomElementConstructor);
