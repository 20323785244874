/**
 * Copyright 2018 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { css } from 'lit-element';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';

export const LdTabStyles = css`
  
      /**
       * -----------------------------------------------------------------------------------------------------------
       * TAB STYLING
       * -----------------------------------------------------------------------------------------------------------
       **/

      paper-tabs {
        text-transform: uppercase;
        /* background-color: var(--platform-frame-tabs-background-color, var(--sw-background-color, #000f26)); */
        background-color: transparent;
        color: var(--platform-frame-tabs-text-color, var(--sw-primary-background-color, #fff));
        --paper-tabs-selection-bar-color: var(--platform-frame-tabs-border-color, var(--sw-primary-highlight-color, #eb0028));
        --paper-tabs-selection-bar: {
          z-index: 10;
        };
      }

      paper-tab {
        flex: none;
        padding: 0 12px;
        position: static;
        overflow: hidden;
        cursor: pointer;
        vertical-align: middle;
        font-family: Roboto,Noto,sans-serif;
        -webkit-font-smoothing: antialiased;
      }

      paper-tab.iron-selected {
        background-color: var(--platform-frame-tabs-active-background-color, var(--sw-secondary-background-color, #737171));
      }

      paper-tab[link] a {
      /* These mixins (from iron-flex-layout) center the link text. */
      color: #fff;
      text-decoration: none;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -ms-flex-direction: row;
      -webkit-flex-direction: row;
      flex-direction: row;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
    }

    @media only screen and (min-width: 600px ) {
      paper-tabs {
        --paper-icon-button: {
        display: inline;
        }
      }
    }

    @media only screen and (min-width: 1024px ) {
      paper-tabs {
        --paper-icon-button: {
          display: none;
        }
      }
      paper-tabs{
        margin-left:-56px;
      }

      @supports (-ms-ime-align:auto) {
        paper-tabs{
          margin-left:0px;
        }
     }
    }

`;

export default LdTabStyles;
