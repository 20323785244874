/**
 * Copyright 2021 Schwäbische Werkzeugmaschinen GmbH
 */

import { connect }             from 'pwa-helpers/connect-mixin';
import { BasicRouter, Router } from '../../../../../js/services/routingConfig';
import { store }               from '../../../../ld-redux/ld-redux-store';
import { LdTranslate } from '../../../../ld-application/i18n/ld-i18next';
import { html, LitElement, property,state }  from 'lit-element';
import { LdSettingsViewStyles }        from '../styles';
import { isUserSwEmployee }   from '../../../../../js/LdGlobal';
import { loadUserInformation }         from '../../../../ld-redux/actions/ld-users-actions';
import '../../../ld-tabs/ld-tabs';
import '../../../ld-form/ld-form-wrapper';


export class LdLimitList extends connect(store)(LitElement) {

  // --------------------------------------------------------------------------------------
  @property({ type: Object, hasChanged: () => true }) _subModules: Array<Object> = [];
  @property({ type: Object }) _userInformation: Object                           = {};
  @property({ type: Object }) _tabs: Array<Object>                           = [
    {
      key: 'list',
      title: LdTranslate('modules.limit.tabs.list'),
      link: '/settings/limit/list',
    },
  ];
  @state() private router: Router                                                                      = new BasicRouter();


  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSettingsViewStyles];
  }

  // --------------------------------------------------------------------------------------
  firstUpdated() {
    if (typeof this._userInformation !== 'undefined') {
      if (!isUserSwEmployee(this._userInformation)) {
        this.router.changeView(`/dashboard`);
        return;
      }
    }
    store.dispatch(loadUserInformation());
  }


  /**
   * Template rendering method
   *
   * @returns {*}
   */
  // --------------------------------------------------------------------------------------
  render() {
    return html`
      <div class='ld-limit-list-wrapper grid-container'>
        <ld-tabs .selected='${this._subModules}' .tabs='${this._tabs}' fallbackSelection='list'></ld-tabs>
        <div class='headline'>
          <h3>${LdTranslate('modules.limit.tabs.list')}</h3>
        </div>
        <div class='ld-limit-wrapper'>
        </div>
        
      </div>
    `;
  }

  /**
   * Redux state management, handles what happens when the state changes
   *
   * @param state
   */
  // --------------------------------------------------------------------------------------
  stateChanged(state: any) {
    this._userInformation = state.LdUsersReducer.info;
    window.dispatchEvent(new Event('LdCloseLoadingSpinner'));

  }

}

customElements.define('ld-limit-list', LdLimitList as unknown as CustomElementConstructor);
