/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

// eslint-disable-next-line import/no-cycle
import {
  CLEAR_ALARMS,
  EMPTY_MULTI_CURRENT,
  ERROR_COLLECTIONS_LIST,
  ERROR_LOADING_ALARMS,
  ERROR_LOADING_AVAILABILITY_KPI_IOT,
  ERROR_LOADING_CHART,
  ERROR_LOADING_CURRENT,
  ERROR_LOADING_DETAILS,
  ERROR_LOADING_MULTI_CURRENT,
  ERROR_LOADING_MULTI_TIMELINE,
  ERROR_LOADING_OVERRIDE,
  ERROR_LOADING_WORKPIECE_COUNTER,
  ERROR_SINGLE_COLLECTION,
  ERROR_VARIABLES_LIST,
  FINISH_COLLECTIONS_LIST,
  FINISH_LOADING_ALARMS,
  FINISH_LOADING_AVAILABILITY_KPI_IOT,
  FINISH_LOADING_CHART,
  FINISH_LOADING_CURRENT,
  FINISH_LOADING_DETAILS,
  FINISH_LOADING_MULTI_CURRENT,
  FINISH_LOADING_MULTI_TIMELINE,
  FINISH_LOADING_OVERRIDE,
  FINISH_LOADING_WORKPIECE_COUNTER,
  FINISH_SINGLE_COLLECTION,
  FINISH_VARIABLES_LIST,
  REMOVE_ENTRY_MULTI_TIMELINE,
  START_COLLECTIONS_LIST,
  START_LOADING_ALARMS,
  START_LOADING_AVAILABILITY_KPI_IOT,
  START_LOADING_CHART,
  START_LOADING_CURRENT,
  START_LOADING_DETAILS,
  START_LOADING_MULTI_CURRENT,
  START_LOADING_MULTI_TIMELINE,
  START_LOADING_OVERRIDE,
  START_LOADING_WORKPIECE_COUNTER,
  START_SINGLE_COLLECTION,
  START_VARIABLES_LIST,
  START_VARIABLES_WITH_LIMITS_LIST,
  FINISH_VARIABLES_WITH_LIMITS_LIST,
  ERROR_VARIABLES_WITH_LIMITS_LIST,
} from '../actions/ld-timeseries-actions';

const LdTimeseriesReducer = (state = {}, action) => {
  switch (action.type) {

    //--------------------------------------------------------------------------------
    case START_LOADING_MULTI_CURRENT:
      if (state.multiCurrentValueList == null) {
        state.multiCurrentValueList = [];
      }
      if (state.isMultiCurrentValueLoading == null) {
        state.isMultiCurrentValueLoading = [];
      }
      state.isMultiCurrentValueLoading = state.isMultiCurrentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.isMultiCurrentValueLoading.push({ chartId: action.chartId, loading: true });
      return {
        ...state,
        isMultiCurrentValueLoading: state.isMultiCurrentValueLoading,
        multiCurrentValueList: state.multiCurrentValueList,
      };
    case FINISH_LOADING_MULTI_CURRENT:

      state.isMultiCurrentValueLoading = state.isMultiCurrentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.isMultiCurrentValueLoading.push({ chartId: action.chartId, loading: false });

      state.multiCurrentValueList = state.multiCurrentValueList.filter(obj => obj.chartId !== action.chartId);
      state.multiCurrentValueList.push({ chartId: action.chartId, multiCurrentValueList: action.multiCurrentValueList });
      return {
        ...state,
        isMultiCurrentValueLoading: state.isMultiCurrentValueLoading,
        multiCurrentValueList: state.multiCurrentValueList,
      };
    case ERROR_LOADING_MULTI_CURRENT:
      state.isMultiCurrentValueLoading = state.isMultiCurrentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.isMultiCurrentValueLoading.push({ chartId: action.chartId, loading: false });

      return {
        ...state,
        result: action.error,
        isMultiCurrentValueLoading: state.isMultiCurrentValueLoading,
      };
    case EMPTY_MULTI_CURRENT:

      if (state.multiCurrentValueList == null) {
        state.multiCurrentValueList = [];
      }
      if (state.isMultiCurrentValueLoading == null) {
        state.isMultiCurrentValueLoading = [];
      }
      state.multiCurrentValueList = state.multiCurrentValueList.filter(obj => obj.chartId !== action.chartId);
      return {
        ...state,
        multiCurrentValueList: state.multiCurrentValueList,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_AVAILABILITY_KPI_IOT:
      return {
        ...state,
        isIotKpiValueLoading: true,
      };
    case FINISH_LOADING_AVAILABILITY_KPI_IOT:
      return {
        ...state,
        iotValueList: action.iotValueList,
        kpiValueList: [action.kpiValueList, action.machineId],
        isIotKpiValueLoading: false,
      };
    case ERROR_LOADING_AVAILABILITY_KPI_IOT:
      return {
        ...state,
        result: action.error,
        isIotKpiValueLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_MULTI_TIMELINE:
      if (typeof state.multiTimeline === 'undefined') {
        state.multiTimeline = [];
      }
      if (typeof state.multiTimelineLoading === 'undefined') {
        state.multiTimelineLoading = [];
      }
      state.multiTimelineLoading = state.multiTimelineLoading.filter(obj => obj.chartId !== action.chartId);
      state.multiTimelineLoading.push({ chartId: action.chartId, loading: true });
      return {
        ...state,
        multiTimelineLoading: state.multiTimelineLoading,
        multiTimeline: state.multiTimeline,
      };
    case FINISH_LOADING_MULTI_TIMELINE:

      state.multiTimelineLoading = state.multiTimelineLoading.filter(obj => obj.chartId !== action.chartId);
      state.multiTimelineLoading.push({ chartId: action.chartId, loading: false });
      let machineId = action.machineId;
      state.multiTimeline = state.multiTimeline.filter(obj => obj.chartId !== action.chartId);
      state.multiTimeline.push({ chartId: action.chartId, timelineData: action.result[machineId] });
      return {
        ...state,
        multiTimelineLoading: state.multiTimelineLoading,
        multiTimeline: state.multiTimeline,
      };

    case ERROR_LOADING_MULTI_TIMELINE:
      state.multiTimelineLoading = state.multiTimelineLoading.filter(obj => obj.chartId !== action.chartId);
      state.multiTimelineLoading.push({ chartId: action.chartId, loading: false });
      return {
        ...state,
        multiTimelineLoading: state.multiTimelineLoading,
        result: action.error,
      };
    case REMOVE_ENTRY_MULTI_TIMELINE:
      state.multiTimelineLoading = state.multiTimelineLoading.filter(obj => obj.chartId !== action.chartId);
      state.multiTimeline = state.multiTimeline.filter(obj => obj.chartId !== action.chartId);
      return {
        ...state,
        multiTimelineLoading: state.multiTimelineLoading,
        multiTimeline: state.multiTimeline,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_CHART:
      switch (action.chartType) {
        case 'uptimeDistribution':
        case 'runtimeMonitor':
        case 'operationMode':
        case 'cycleTime':
        case 'availability':
        case 'axisTest':
        case 'combinedAvailability':
          if (typeof state.chartValue === 'undefined') {
            state.chartValue = [];
          }
          if (typeof state.chartValueLoading === 'undefined') {
            state.chartValueLoading = [];
          }
          state.chartValueLoading = state.chartValueLoading.filter(obj => obj.chartId !== action.chartId);
          state.chartValueLoading.push({ chartId: action.chartId, loading: true });
          return {
            ...state,
            chartValueLoading: state.chartValueLoading,
            chartValue: state.chartValue,
          };
        default:
          return state;
      }

    case FINISH_LOADING_CHART:
      switch (action.chartType) {
        case 'uptimeDistribution':
        case 'runtimeMonitor':
        case 'operationMode':
        case 'cycleTime':
        case 'axisTest':
        case 'availability':
        case 'combinedAvailability':
          state.chartValueLoading = state.chartValueLoading.filter(obj => obj.chartId !== action.chartId);
          state.chartValueLoading.push({ chartId: action.chartId, loading: false });
          state.chartValue = state.chartValue.filter(obj => obj.chartId !== action.chartId);
          state.chartValue.push({ chartId: action.chartId, timelineData: action.result });
          return {
            ...state,
            chartValueLoading: state.chartValueLoading,
            chartValue: state.chartValue,
          };
        default:
          return state;
      }

    case ERROR_LOADING_CHART:
      switch (action.chartType) {
        case 'uptimeDistribution':
        case 'runtimeMonitor':
        case 'operationMode':
        case 'cycleTime':
        case 'availability':
        case 'axisTest':
        case 'combinedAvailability':
          state.chartValueLoading = state.chartValueLoading.filter(obj => obj.chartId !== action.chartId);
          state.chartValueLoading.push({ chartId: action.chartId, loading: false });
          return {
            ...state,
            chartValueLoading: state.chartValueLoading,
            result: action.error,
          };
        default:
          return state;
      }


    //--------------------------------------------------------------------------------
    case START_LOADING_WORKPIECE_COUNTER:

      if (typeof state.workpieceCounterData === 'undefined') {
        state.workpieceCounterData = [];
      }
      if (typeof state.workpieceCounterLoading === 'undefined') {
        state.workpieceCounterLoading = [];
      }
      state.workpieceCounterLoading = state.workpieceCounterLoading.filter(obj => obj.machineId !== action.machineId);
      state.workpieceCounterLoading.push({ machineId: action.machineId, loading: true });
      return {
        ...state,
        workpieceCounterLoading: state.workpieceCounterLoading,
        workpieceCounterData: state.workpieceCounterData,
      };
    case FINISH_LOADING_WORKPIECE_COUNTER:
      state.workpieceCounterLoading = state.workpieceCounterLoading.filter(obj => obj.machineId !== action.machineId);
      state.workpieceCounterLoading.push({ machineId: action.machineId, loading: false });
      state.workpieceCounterData = state.workpieceCounterData.filter(obj => obj.machineId !== action.machineId);
      state.workpieceCounterData.push({ machineId: action.machineId, workpieceCounterData: action.workpieceData });
      return {
        ...state,
        workpieceCounterLoading: state.workpieceCounterLoading,
        workpieceCounterData: state.workpieceCounterData,
      };
    case ERROR_LOADING_WORKPIECE_COUNTER:
      state.workpieceCounterLoading = state.workpieceCounterLoading.filter(obj => obj.machineId !== action.machineId);
      state.workpieceCounterLoading.push({ machineId: action.machineId, loading: false });
      return {
        ...state,
        workpieceCounterLoading: state.workpieceCounterLoading,
        result: action.error,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_DETAILS:

      if (typeof state.detailData === 'undefined') {
        state.detailData = [];
      }
      if (typeof state.detailDataLoading === 'undefined') {
        state.detailDataLoading = [];
      }
      state.detailDataLoading = state.detailDataLoading.filter(obj => obj.machineId !== action.machineId);
      state.detailDataLoading.push({ machineId: action.machineId, loading: true });
      return {
        ...state,
        detailDataLoading: state.detailDataLoading,
        detailData: state.detailData,
      };
    case FINISH_LOADING_DETAILS:
      state.detailDataLoading = state.detailDataLoading.filter(obj => obj.machineId !== action.machineId);
      state.detailDataLoading.push({ machineId: action.machineId, loading: false });
      state.detailData = state.detailData.filter(obj => obj.machineId !== action.machineId);
      state.detailData.push({ machineId: action.machineId, detailData: action.detailData });
      return {
        ...state,
        detailDataLoading: state.detailDataLoading,
        detailData: state.detailData,
      };
    case ERROR_LOADING_DETAILS:
      state.detailDataLoading = state.detailDataLoading.filter(obj => obj.machineId !== action.machineId);
      state.detailDataLoading.push({ machineId: action.machineId, loading: false });
      return {
        ...state,
        detailDataLoading: state.detailDataLoading,
        result: action.error,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_ALARMS:
      switch (action.alarmsType) {
        case 'alarmTop':
          if (typeof state.alarmsTopValue === 'undefined') {
            state.alarmsTopValue = [];
          }
          if (typeof state.alarmsValueLoading === 'undefined') {
            state.alarmsValueLoading = [];
          }
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: true });
          return {
            ...state,
            alarmsTopValueLoading: state.alarmsValueLoading,
            alarmsTopValue: state.alarmsTopValue,
          };
        case 'alarmList':
          if (typeof state.alarmsListValue === 'undefined') {
            state.alarmsListValue = [];
          }
          if (typeof state.alarmsValueLoading === 'undefined') {
            state.alarmsValueLoading = [];
          }
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: true });
          return {
            ...state,
            alarmsListValueLoading: state.alarmsValueLoading,
            alarmsListValue: state.alarmsListValue,
          };
        default:
          return state;
      }

    case CLEAR_ALARMS:
      switch (action.alarmsType) {
        case 'alarmTop':
          return {
            ...state,
            alarmsTopValue: [],
          };
        case 'alarmList':
          return {
            ...state,
            alarmsListValue: [],
          };
        default:
          return state;
      }

    case FINISH_LOADING_ALARMS:
      switch (action.alarmsType) {
        case 'alarmTop':
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: false });
          state.alarmsTopValue = state.alarmsTopValue.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsTopValue.push({ alarmsId: action.alarmsId, timelineData: action.result });
          return {
            ...state,
            alarmsTopValueLoading: state.alarmsValueLoading,
            alarmsTopValue: state.alarmsTopValue,
          };
        case 'alarmList':
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: false });
          state.alarmsListValue = state.alarmsListValue.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsListValue.push({ alarmsId: action.alarmsId, timelineData: action.result });
          return {
            ...state,
            alarmsListValueLoading: state.alarmsValueLoading,
            alarmsListValue: state.alarmsListValue,
          };
        default:
          return state;
      }

    case ERROR_LOADING_ALARMS:
      switch (action.alarmsType) {
        case 'alarmTop':
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: false });
          return {
            ...state,
            alarmsTopValueLoading: state.alarmsValueLoading,
            result: action.error,
          };
        case 'alarmList':
          state.alarmsValueLoading = state.alarmsValueLoading.filter(obj => obj.alarmsId !== action.alarmsId);
          state.alarmsValueLoading.push({ alarmsId: action.alarmsId, loading: false });
          return {
            ...state,
            alarmsListValueLoading: state.alarmsValueLoading,
            result: action.error,
          };
        default:
          return state;
      }

    //--------------------------------------------------------------------------------
    case START_LOADING_OVERRIDE:
      if (typeof state.overrideValue === 'undefined') {
        state.overrideValue = [];
      }
      if (typeof state.overrideValueLoading === 'undefined') {
        state.overrideValueLoading = [];
      }
      state.overrideValueLoading = state.overrideValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.overrideValueLoading.push({ chartId: action.chartId, loading: true });
      return {
        ...state,
        overrideValueLoading: state.overrideValueLoading,
        overrideValue: state.overrideValue,
      };

    case FINISH_LOADING_OVERRIDE:
      state.overrideValueLoading = state.overrideValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.overrideValueLoading.push({ chartId: action.chartId, loading: false });
      state.overrideValue = state.overrideValue.filter(obj => obj.chartId !== action.chartId);
      state.overrideValue.push({ chartId: action.chartId, overrideValue: action.overrideValue });
      return {
        ...state,
        overrideValueLoading: state.overrideValueLoading,
        overrideValue: state.overrideValue,
      };

    case ERROR_LOADING_OVERRIDE:
      state.overrideValueLoading = state.overrideValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.overrideValueLoading.push({ chartId: action.chartId, loading: false });
      return {
        ...state,
        overrideValueLoading: state.overrideValueLoading,
        result: action.error,
      };

    //--------------------------------------------------------------------------------
    case START_LOADING_CURRENT:
      if (typeof state.currentValue === 'undefined') {
        state.currentValue = [];
      }
      if (typeof state.currentValueLoading === 'undefined') {
        state.currentValueLoading = [];
      }
      state.currentValueLoading = state.currentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.currentValueLoading.push({ chartId: action.chartId, loading: true });
      return {
        ...state,
        currentValueLoading: state.currentValueLoading,
        currentValue: state.currentValue,
      };

    case FINISH_LOADING_CURRENT:
      state.currentValueLoading = state.currentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.currentValueLoading.push({ chartId: action.chartId, loading: false });
      state.currentValue = state.currentValue.filter(obj => obj.chartId !== action.chartId);
      state.currentValue.push({ chartId: action.chartId, currentValue: action.currentValue != null ? action.currentValue[action.machineId] : ''});
      return {
        ...state,
        currentValueLoading: state.currentValueLoading,
        currentValue: state.currentValue,
      };

    case ERROR_LOADING_CURRENT:
      state.currentValueLoading = state.currentValueLoading.filter(obj => obj.chartId !== action.chartId);
      state.currentValueLoading.push({ chartId: action.chartId, loading: false });
      return {
        ...state,
        currentValueLoading: state.currentValueLoading,
        result: action.error,
      };

    //--------------------------------------------------------------------------------
    case START_VARIABLES_LIST:
      return {
        ...state,
        isVariablesListLoading: true,
      };
    case FINISH_VARIABLES_LIST:
      return {
        ...state,
        variablesList: action.variablesList,
        isVariablesListLoading: false,
      };
    case ERROR_VARIABLES_LIST:
      return {
        ...state,
        variablesList: null,
        variablesListError: action.error,
        isVariablesListLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_VARIABLES_WITH_LIMITS_LIST:
      return {
        ...state,
        isVariablesWithLimitsListLoading: true,
      };
    case FINISH_VARIABLES_WITH_LIMITS_LIST:
      return {
        ...state,
        variablesWithLimitsList: action.variablesWithLimitsList,
        isVariablesWithLimitsListLoading: false,
      };
    case ERROR_VARIABLES_WITH_LIMITS_LIST:
      return {
        ...state,
        variablesWithLimitsList: null,
        variablesWithLimitsListError: action.error,
        isVariablesWithLimitsListLoading: false,
      };
    //--------------------------------------------------------------------------------
    case START_COLLECTIONS_LIST:
      return {
        ...state,
        isCollectionsListLoading: true,
      };
    case FINISH_COLLECTIONS_LIST:
      return {
        ...state,
        collectionsList: action.collectionsList,
        isCollectionsListLoading: false,
      };
    case ERROR_COLLECTIONS_LIST:
      return {
        ...state,
        collectionsList: null,
        collectionsListError: action.error,
        isCollectionsListLoading: false,
      };

    //--------------------------------------------------------------------------------
    case START_SINGLE_COLLECTION:
      return {
        ...state,
        isSingleCollectionLoading: true,
      };
    case FINISH_SINGLE_COLLECTION:
      return {
        ...state,
        singleCollection: action.singleCollection,
        isSingleCollectionLoading: false,
      };
    case ERROR_SINGLE_COLLECTION:
      return {
        ...state,
        singleCollection: null,
        singleCollectionError: action.error,
        isSingleCollectionLoading: false,
      };

    //--------------------------------------------------------------------------------
    default:
      return state;
  }
};

export default LdTimeseriesReducer;
