/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import { CONFIG } from '../LdGlobal';
import LdFetch from '../LdFetch';

const { base_url, endpoints } = CONFIG.backend.services.timeseries;
const url = `${base_url}${endpoints.multi_timeline.current}`;

function loadMultiTimeseries(machineIds, variables) {
  const payload = {
    machineIds,
    variables
  };
  return new Promise((resolve, reject) => {
    LdFetch.post(url, payload, resolve, reject);
  });
}

export default {
  loadMultiTimeseries,
};
