/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import {
  UPDATE_LOCATION,
  START_LOADING_SUBMIT,
  FINISH_LOADING_SUBMIT,
  ERROR_LOADING_SUBMIT,
  CLEAR_SUBMIT,
  START_LOADING_MANUALS,
  FINISH_LOADING_MANUALS,
  ERROR_LOADING_MANUALS,
  ERROR_DOWNLOAD_LOADING_FILES,
  FINISH_DOWNLOAD_LOADING_FILES,
  START_DOWNLOAD_LOADING_FILES,
  CLEAR_DOWNLOAD_LOADING_MANUALS,
  START_UPLOAD,
  FINISH_UPLOAD,
  ERROR_UPLOAD,
  START_LOADING_VERSIONS,
  FINISH_LOADING_VERSIONS,
	ERROR_LOADING_VERSIONS,
	START_LOADING_RELEASE_INFO,
	FINISH_LOADING_RELEASE_INFO,
	ERROR_LOADING_RELEASE_INFO,
	FINISH_LOADING_NCSTATE,
	ERROR_LOADING_NCSTATE,
	START_LOADING_NCSTATE,
  START_FILES_DELETE,
  FINISH_FILES_DELETE,
  ERROR_FILES_DELETE, START_LOADING_SUBMIT_NAME, FINISH_LOADING_SUBMIT_NAME, ERROR_LOADING_SUBMIT_NAME,

} from '../actions/ld-application-actions';

const LdApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        pageModule: action.pageModule,
        subModules: action.subModules
      };
    case START_LOADING_SUBMIT:
      return {
        ...state,
        loadingSubmit: true
      };
    case FINISH_LOADING_SUBMIT:
      return {
        ...state,
        loadingSubmit: false,
        successSubmit: action.successSubmit,
        successSubmitMail: true,

      };
    case ERROR_LOADING_SUBMIT:
      return {
        ...state,
        result: action.error,
        loadingSubmit: false,
        successSubmit: null
      };
    case CLEAR_SUBMIT:
      return {
        ...state,
        successSubmit: null,
        successSubmitMail: null,
        upload: false,
        successSubmitName: false,
        loadingSubmitName: false,
        loadingSubmit: false,
        filesDelete: null,
        result: null,
        loadingUpload: false,
        serviceManuals: null,
        isFilesDeleteLoading: false
      };
    case START_LOADING_MANUALS:
      return {
        ...state,
        loadingManuals: true
      };
    case FINISH_LOADING_MANUALS:
      return {
        ...state,
        loadingManuals: false,
        serviceManuals: action.serviceManuals
      };
    case ERROR_LOADING_MANUALS:
      return {
        ...state,
        result: action.error,
        loadingManuals: false
      };
    case START_LOADING_VERSIONS:
      return {
        ...state,
        loadingVersion: true
      };
    case FINISH_LOADING_VERSIONS:
      return {
        ...state,
        loadingVersion: false,
        versions: action.serviceVersion
      };
    case ERROR_LOADING_VERSIONS:
      return {
        ...state,
        result: action.error,
        loadingVersion: false
      };
    case START_DOWNLOAD_LOADING_FILES:
      return {
        ...state,
        loadingManuals: true
      };
    case FINISH_DOWNLOAD_LOADING_FILES:
      return {
        ...state,
        loadingManuals: false,
        fileId: action.fileId,
        downloadServiceFiles: action.downloadServiceFile
      };
    case CLEAR_DOWNLOAD_LOADING_MANUALS:
      return {
        ...state,
        loadingManuals: false,
        downloadServiceFile: null,
        versions: null,
        serviceManuals: null,
      };
    case ERROR_DOWNLOAD_LOADING_FILES:
      return {
        ...state,
        result: action.error,
        loadingManuals: false
      };
    case START_UPLOAD:
      return {
        ...state,
        loadingUpload: true
      };
    case FINISH_UPLOAD:
      return {
        ...state,
        loadingUpload: false,
        upload: true,
      };
    case ERROR_UPLOAD:
      return {
        ...state,
        result: action.error,
        loadingUpload: false
      };
    case START_LOADING_NCSTATE:
      return {
        ...state,
        loadingNC: true,
      };
    case FINISH_LOADING_NCSTATE:
      return {
        ...state,
        loadingNC: false,
        serviceNC: action.serviceNC,
      };
    case ERROR_LOADING_NCSTATE:
      return {
        ...state,
        result: action.error,
        loadingNC: false,
      };
    //--------------------------------------------------------------------------------
    case START_FILES_DELETE:
      return {
        ...state,
        isFilesDeleteLoading: true,
      };
    case FINISH_FILES_DELETE:
      return {
        ...state,
        filesDelete: action.filesDelete,
        isFilesDeleteLoading: false,
      };
    case ERROR_FILES_DELETE:
      return {
        ...state,
        result: action,
        isFilesDeleteLoading: false,
      };
    case START_LOADING_SUBMIT_NAME:
      return {
        ...state,
        loadingSubmitName: true,
      };
    case FINISH_LOADING_SUBMIT_NAME:
      return {
        ...state,
        loadingSubmitName: false,
        successSubmitName: true,

      };
    case ERROR_LOADING_SUBMIT_NAME:

      return {
        ...state,
        result: action.error,
        loadingSubmitName: false,
        successSubmitName: false,
      };
	  case START_LOADING_RELEASE_INFO:
		  return {
			  ...state,
			  loadingReleaseInfo: true,
		  };
	  case FINISH_LOADING_RELEASE_INFO:
		  return {
			  ...state,
			  loadingReleaseInfo: false,
			  releaseInfo: action.releaseInfo,
		  };
	  case ERROR_LOADING_RELEASE_INFO:
		  return {
			  ...state,
			  result: action.error,
			  loadingReleaseInfo: false,
		  };
    default:
      return state;
  }
};

export default LdApplicationReducer;
