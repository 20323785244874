/**
 * Copyright 2023 Schwäbische Werkzeugmaschinen GmbH
 */
import { TemplateResult } from 'lit-html';

interface IFBreadcrumbObject {
  parent: string,
  label: string,
  uri: string
}

interface IFBreadCrumbReducer {
  breadCrumbConfig: IFBreadCrumbConfigObject;
}

interface IFManuals {
  url: string,
  fields: any,

}

interface IFBreadCrumbConfigObject {
  customBreadcrumbElements?: Array<IFBreadcrumbObject> | null,
  assetType?: string |null,
  activeLastCrumb?: boolean,
  assetID?: string | null,
  backButtonLink?: string | null,
}


export const VisibilityType = {
  External: 'EXTERNAL',
  Internal: 'INTERNAL',
  All: 'ALL',
};

interface IFNcStateFileData {
  creationDate: number,
  event_type: string,
  trigger: string,
  machine_id: string,
  timestamp: number,
}

interface IFAlarms {
  alarm_number: string,
  categoryText: string,
  details: string,
  machineTimeFormatted?: string,
  leftTimeFormatted?: string,
  durationFormatted?: string,
  machine_time?: string,
  duration?: string,
  left_time?: string,
  category?: number,
  trend?: TemplateResult,
  countBeforehandTimeRange?:number,
  countSelectedTimeRange?:number,
  count?: number
}

export {
  IFBreadcrumbObject,
  IFBreadCrumbReducer,
  IFBreadCrumbConfigObject,
  IFNcStateFileData,
  IFManuals,
  IFAlarms,
};
